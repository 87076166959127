<template src="./ElectionVote.html" />
<script>
import Seats from "./Seats.vue";
import sizeReporter from "../mixins/sizeReporter";

export default {
  components: {Seats},
  mixins: [ sizeReporter ],
  data() {
    return {
      key: null,
      votes: [94, 92, 13],
      savedVote: false,
    }
  },
  computed: {
    title() {
      return this.savedVote
          ? 'A tipped alapján így nézne ki az Országgyűlés összetétele:'
          : 'Olvasóink tippjei alapján így nézne ki az Országgyűlés összetétele:'
    },
    lead() {
      return this.savedVote
          ? 'Mentsd el a tipped linkjét, hogy a választások napján visszanézhesd!'
          : 'Összesen 33 322 szavazat alapján.'
    },
    shareLink() {
      return process.browser
          ? window.location.href + (this.key ? '/' + this.key : '')
          : ''
    },
  },
  methods: {
    copyText() {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.value = window.location.href
      input.select()
      input.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(input)
      const tooltip = document.getElementById('tooltip')
      tooltip.style.opacity = '1'
      setTimeout(this.outFunc, 1000)
    },
    outFunc() {
      const tooltip = document.getElementById('tooltip')
      tooltip.style.opacity = '0'
    },
  },
  head() {
    return {
      title: 'Telex: Választás 2022 - Tippjáték',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Telex: Választás 2022 - Tippjáték',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Telex: Választás 2022 - Tippjáték',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://telex.hu/tippjatek_fb_clk.jpg',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/media.scss';
$font-regular: 400;
$font-bold: 700;
$primary: #096;
$accent: $primary;
$theme: background-color .3s ease-out;
$theme-border: border .3s ease-out;
$theme-txt: color .3s ease-out;
$theme-fill: fill .3s ease-out;
.seats-22 {
  --ov-egybprtok: #85e;
  --ov-egysgbenmagyarorszgrt: #5ba;
  margin: 0 0 60px 0;
  display: flex;
  flex-direction: column;
  &__subtitle {
    font-size: .9rem;
    display: inline;
    font-weight: $font-regular;
    margin: 7px 0 0 0;
    svg {
      fill: var(--color-accent);
      margin: -3px 3px 0 0;
      width: 12px;
    }
    span {
      text-align: center;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 40px 0 20px 0;
    @include l {
      justify-content: center;
    }
    span {
      font-weight: $font-bold;
      margin: 5px 30px 5px 20px;
      position: relative;
      font-size: .88rem;
      &:before {
        position: absolute;
        content:'';
        top: 50%;
        transform: translateY(-50%);
        left: -15px;
        width: 4px;
        height: 4px;
        transition: $theme-border;
        border-radius: 16px;
      }
      &:nth-child(1):before {
        box-shadow: 0 0 0 5px #f70;;
      }
      &:nth-child(2):before {
        box-shadow: 0 0 0 5px #5ba;
      }
      &:nth-child(3):before {
        box-shadow: 0 0 0 5px #85e;
      }
    }
  }
  &__btn {
    background: var(--color-secondary);
    color: var(--color-bg);
    transition: $theme, $theme-txt;
    height: 42px;
    margin: 0 10px 12px 0;
    font-size: .88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: var(--color-bg);
      transition: $theme-fill;
    }
    &--ghost {
      color: var(--color-secondary);
      background: transparent;
      border: 1px solid var(--color-secondary);
      transition: $theme, $theme-txt, $theme-border;
      height: 42px;
      margin: 0 10px 12px 0;
      &:hover {
        opacity: .7 !important;
      }
    }
    &--action {
      background: var(--color-accent);
      margin: 0;
    }
    &.share-network-facebook {
      padding-left: 10px;
      svg {
        //transform: translateY(-2px);
      }
    }
  }
  &__btns {
    margin: 20px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
      white-space: nowrap;
      &:first-child {
        svg {
          margin-right: 3px;
        }
      }
    }
    .options {
      background: var(--color-secondary);
      transition: $theme-txt;
      display: grid;
      place-content: center;
      width: 40px;
      height: 40px;
      &:hover {
        background: var(--color-secondary);
        opacity: .9;
      }
      svg {
        fill: var(--color-bg);
        transition: $theme-fill;
      }
    }
  }
  &__patko {
    margin: 5px;
    @include m {
      margin: 10px 40px;
    }
    & > *:first-of-type {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px 60px;
      align-items: center;
      justify-items: center;
      @include xl {
        grid-template-columns: 300px 160px;
        grid-gap: 15px;
        align-items: center;
      }
    }
  }
  &__success {
    width: 110px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50% ) translateY(-50%);
    @include m {
      width: 150px;
    }
    @include l {
      width: 170px;
    }
  }
  &__gfx {
    width: 100%;
    height: 0;
    padding-top: 40%;
    //background: var(--color-secondary);
    background: var(--color-gray-mid-light);
    margin: 10px 0 20px 0;
    position: relative;
    @include m {
      margin: 10px 0 30px 0;
      padding-top: 30%;
    }
  }
  .tooltip {
    transform: translateX(-70px) translateY(-35px);
  }
}
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  //background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  //opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 40px;
    background: #135;
    cursor: pointer;
    border-radius: 10px;
    transform: translateY(-20px);
  }
  &::-moz-range-thumb {
    width: 15px;
    height: 40px;
    background: #135;
    cursor: pointer;
    border-radius: 10px;
    transform: translateY(-20px);
  }
  &--right,
  &--left {
    background: transparent;
    height: 0;
  }
}
.ov-slider {
  width: calc(100% - 80px);
  margin: 0 0 60px 0;
  &__items {
    position: relative;
    height: 25px;
    margin: 30px 0;
  }
  input[type=text] {
    background: var(--color-gray-light);
    border: 1px solid var(--color-gray-mid-light);
    color: var(--color-text);
    transition: $theme, $theme-border, $theme-txt;
    border-radius: 5px;
    width: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    right: -80px;
    height: 34px;
    text-align: center;
    font-size: 1.1rem;
  }
  input[type=range] {
    //clear
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
    &:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }
    &::-ms-track {
      width: 100%;
      cursor: pointer;

      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    //thumb
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      //border: 3px solid #333;
      box-shadow: 0 0 0 3px var(--color-bg);
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background: var(--color-bg);
      cursor: pointer;
      margin-top: -11px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }
    &::-moz-range-thumb {
      box-shadow: 0 0 0 3px var(--color-bg);
      width: 10px;
      height: 10px;
      border-radius: 30px;
      background: var(--color-bg);
      cursor: pointer;
    }
    &::-ms-thumb {
      box-shadow: 0 0 0 3px var(--color-bg);
      width: 10px;
      height: 10px;
      border-radius: 30px;
      background: var(--color-bg);
      cursor: pointer;
    }
    //track
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      border-radius: 10px;
    }
    &::-moz-range-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      border-radius: 10px;
    }
  }
  #slider0 {
    &[type=range]::-webkit-slider-runnable-track {
      background: #f70;
    }
    &[type=range]::-moz-range-track {
      background: #f70;
    }
    &[type=range]::-webkit-slider-thumb {
      border: 10px solid #f70;
    }
    &[type=range]::-moz-range-thumb {
      border: 10px solid #f70;
    }
  }
  #slider1 {
    &[type=range]::-webkit-slider-runnable-track {
      background: #5ba;
    }
    &[type=range]::-moz-range-track {
      background: #5ba;
    }
    &[type=range]::-webkit-slider-thumb {
      border: 10px solid #5ba;
    }
    &[type=range]::-moz-range-thumb {
      border: 10px solid #5ba;
    }
  }
  #slider2 {
    &[type=range]::-webkit-slider-runnable-track {
      background: #85e;
    }
    &[type=range]::-moz-range-track {
      background: #85e;
    }
    &[type=range]::-webkit-slider-thumb {
      border: 10px solid #85e;
    }
    &[type=range]::-moz-range-thumb {
      border: 10px solid #85e;
    }
  }
  /*
  //ms
  input[type=range]::-ms-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }
  input[type=range]:focus::-ms-fill-lower {
    //background: purple;
  }
  input[type=range]::-ms-fill-upper {
    background: purple;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }
  input[type=range]:focus::-ms-fill-upper {
    //background: green;
  }
   */
}
#tooltip {
  opacity: 0;
}
</style>
