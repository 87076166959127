<template src="./Map.html"/>
<script>
import { version } from '../../package'
import Config from '@/config/Map.json'
import ElectionApi from "@/api/ElectionApi"
export default {
  components: {
  },
  props: {
    propCounty: {
      type: String,
      required: false,
      default: null,
    },
    propDistrict: {
      type: String,
      required: false,
      default: null,
    },
    version: {
      type: String,
      default: version,
    }
  },
  data() {
    return {
      map: null,
      state: Config.defaultState,
      states: JSON.parse(JSON.stringify(Config.mapStates)),
      selectedLayerChanged: null,
      data: {},
      search: {
        show: false,
      },
    }
  },
  watch: {
    state(newValue) {
      this.$emit('stateChanged', newValue)
      window.parent.postMessage({ event: 'stateChanged', newValue: newValue }, '*')
    },
    'states.county.selected': {
      immediate: true,
      handler(newValue) {
        this.$emit('countyChanged', newValue)
        if (window && window.parent) {
          window.parent.postMessage({event: 'countyChanged', newValue: newValue}, '*')
        }
      }
    },
    'states.district.selected'(newValue) {
      this.$emit('districtChanged', newValue)
      if (window && window.parent) {
        window.parent.postMessage({event: 'districtChanged', newValue: newValue}, '*')
      }
    },
  },
  mounted() {
    this.loadData().then(data => {
      this.data.candidates = data[0]
      this.data.history = data[1]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
    getSelectedCountyName() {
      this.selectedLayerChanged
      const selectedLayer = this.getSelectedLayer()
      let county = (selectedLayer && selectedLayer.feature.properties.name) || ''
      return county === 'Budapest' ? 'budapest' : county
    },
    getSelectedDistrictName() {
      this.selectedLayerChanged
      const selectedLayer = this.getSelectedLayer()
      let district = (selectedLayer && selectedLayer.feature.properties.oevk_center) || ''
      return district
    },
  },
  methods: {
    async loadData() {
      return Promise.all([
        ElectionApi.getCandidates(),
        ElectionApi.getCandidatesHistory(),
      ])
    },
    init() {
      this.state = this.propCounty || this.$route.query.county ? "district" : Config.defaultState
      this.states.county.selected = this.propCounty || this.$route.query.county || '01'
      this.states.county.data = this._mapData.geoCountyData
      this.states.district.selected = this.propDistrict || this.$route.query.district || null
      this.states.district.data = this._mapData.geoDistrictData
      this.map = this.$L.map('map' + this._uid, {
        ...Config.mapOptions,
        ...{
          dragging: !this.$L.Browser.mobile,
          tap: !this.$L.Browser.mobile }
      })
    },
    show() {
      // get current state
      const currentState = this.getCurrentState()
      // get map layers from geodata
      currentState.layers = this.getLayers(this.getLayersData())
      // get selected layer
      const selectedLayer = this.getSelectedLayer()
      // set selected layer style and value
      selectedLayer && selectedLayer.setStyle(Config.mapStyles.selected)
      selectedLayer && this.setSelected(selectedLayer.feature.properties[currentState.key])
      // set bounds
      this.map.flyToBounds(currentState.layers.getBounds(), Config.mapStyles.animate)
      // add layers to map
      currentState.layers.addTo(this.map)
    },
    getLayersData() {
      const currentState = this.getCurrentState()
      return currentState.data.features.filter(feature => !currentState.parent || (feature.properties[currentState.parent]?.toString() === this.states[currentState.parentState]?.selected?.toString()))
    },
    getLayers(data) {
      return this.$L.geoJSON(data, {
        style: Config.mapStyles.default,
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              const layer = e.target
              layer.setStyle({...Config.mapStyles.selected, ...Config.mapStyles.hover})
            },
            mouseout: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              layer.setStyle((layer.feature.properties[currentState.key]?.toString() === this.getSelected()?.toString()) ? Config.mapStyles.selected : Config.mapStyles.default)
            },
            click: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              const selectedLayer = this.getSelectedLayer()
              selectedLayer && selectedLayer.setStyle(Config.mapStyles.default)
              layer.setStyle(Config.mapStyles.selected)
              this.setSelected(layer.feature.properties[currentState.key])
            },
          })
        }
      })
    },
    getCurrentState() {
      return this.states[this.state]
    },
    getSelected() {
      return this.getCurrentState().selected
    },
    getSelectedLayer() {
      const currentState = this.getCurrentState()
      if (!currentState || !currentState.layers)
        return null
      const selectedLayer = currentState.layers.getLayers().filter(
          layer => layer.feature.properties[currentState.key]?.toString() === (currentState.selected || '')?.toString()
        )[0]
      return this.selectedLayerChanged = (selectedLayer || currentState.layers.getLayers()[0])
    },
    setSelected(value) {
      this.getCurrentState().selected = value
    },
    onBack() {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = this.getCurrentState().parentState || Config.defaultState
      this.show()
    },
    onNext() {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = this.getCurrentState().childState || Config.defaultState
      this.show()
    },
    onShowSearch() {
      this.search.show = true
      this.$nextTick(() => {
        this.$refs.searchSelect.$el.querySelector('input').focus()
      })
    },
    onSearch(search) {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = "county"
      this.setSelected(search.maz)
      this.state = "district"
      this.setSelected(search.oevkId)
      this.search.show = false
      this.show()
    },
    filterSearch(options, label, search) {
      return (options.nev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) ||
        (options.oevkNev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) ||
        (options.jeloloCsoport.nev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
    },
    getCandidates() {
      return this.data.candidates.filter((item) => item.oevkId === this.getCurrentState().selected?.toString())
    },
    getOrderedCandidates() {
      return this.data.candidates.sort((e1, e2) => e1.oevkId - e2.oevkId)
    },
    getHistory() {
      const items = this.data.history.filter((item) => item.oevkId === this.getCurrentState().selected?.toString())
      const sum = items.reduce((sum, item) => sum + item.szavazat, 0)
      items.map((item) => item.percent = Math.round(((item.szavazat / sum) * 100) * 10) / 10)
      items.sort((e1, e2) => e2.percent - e1.percent)
      return items
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/map.scss';
</style>
