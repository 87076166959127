import Config from '@/config/Api.json'

export default {

    getStatus() {
        return fetch(Config.url + Config.status).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getParticipations() {
        return fetch(Config.url + Config.participations).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getResults() {
        return fetch(Config.url + Config.results).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getCandidates() {
        return fetch(Config.url + Config.candidates).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getCandidatesHistory() {
        return fetch(Config.url + Config.candidates_history).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getDistricts() {
        return fetch(Config.url + Config.districts).then(result => result.ok && result.json() || Promise.reject(result))
    },

    getDistrictsHistory() {
        return fetch(Config.url + Config.districts_history).then(result => result.ok && result.json() || Promise.reject(result))
    },

    // TODO: move to config
    getOevkList(year) {
      return fetch(Config.url + '/' + year + '/valasztokeruletek').then(result => result.ok && result.json() || Promise.reject(result))
    },

  // TODO: move to config
    getOvList(year) {
      return fetch(Config.url + '/' + year + '/listak').then(result => result.ok && result.json() || Promise.reject(result))
    },

    getResultsTips(tips) {
        tips = tips.split(',')
        const result = [
            {
                name: "Fidesz-KDNP",
                value: tips[0],
                class: 1,
            },
            {
                name: "Egységben Magyarországért",
                value: tips[1],
                class: 2,
            },
            {
                name: "Egyéb pártok",
                value: tips[2],
                class: 3,
            }
        ]
        return Promise.resolve([result[0], result[2], result[1], ])
    },

}
