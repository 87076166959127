<template src="./ParticipationBar.html"/>
<script>
import ElectionApi from '@/api/ElectionApi.js'
const historyData = {
  y2018: {
    "07:00": {
      "szazalek": "2.24"
    },
    "09:00": {
      "szazalek": "13.17"
    },
    "11:00": {
      "szazalek": "29.93"
    },
    "13:00": {
      "szazalek": "42.32"
    },
    "15:00": {
      "szazalek": "53.64"
    },
    "17:00": {
      "szazalek": "63.21"
    },
    "18:30": {
      "szazalek": "68.13"
    },
    "19:00": {
      "szazalek": "70.22"
    },
  },
  y2002: {
    "07:00": {
      "szazalek": "2.0"
    },
    "09:00": {
      "szazalek": "12.0"
    },
    "11:00": {
      "szazalek": "30.27"
    },
    "13:00": {
      "szazalek": "42.82"
    },
    "15:00": {
      "szazalek": "53.59"
    },
    "17:00": {
      "szazalek": "67.87"
    },
    "18:30": {
      "szazalek": "67.87"
    },
    "19:00": {
      "szazalek": "73.51"
    },
  },
}
export default {
  components: {
  },
  props: {
    history: {
      type: String,
      default: null,
    },
    list: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      data: {},
      participation: {},
      participation_history: {
        y2018: 0,
        y2002: 0,
      },
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.data.participations = data[0]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
  },
  methods: {
    async loadData() {
      return Promise.all([
          ElectionApi.getParticipations(),
      ])
    },
    init() {
      this.participation = this.data.participations.reduce((prev, p) => prev.szazalek < p.szazalek ? p : prev, { szazalek: 0}) || {}
      this.participation_history = {
        y2018: historyData.y2018[this.participation.idopont]?.szazalek || 0,
        y2002: historyData.y2002[this.participation.idopont]?.szazalek || 0,
      }
    },
    show() {
      let message = {
        type: 'iframe-size',
        sourceAddress: window.location.href,
        height: document.body.scrollHeight + 20,
        width: document.body.scrollWidth
      };
      // window.top refers to parent window
      this.$nextTick(() => window.parent.postMessage(message, '*'))
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
</style>
