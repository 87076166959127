<template src="./MyDistrict.html"/>
<script>
import ElectionApi from '@/api/ElectionApi.js'
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      data: {},
      districts: null,
      myDistrict: null,
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.data.districts = data[0]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
  },
  methods: {
    async loadData() {
      return Promise.all([
        ElectionApi.getDistricts(),
      ])
    },
    sortData(d) {
      d.jeloltek = d.jeloltek.sort((e1, e2) => e2.szazalek - e1.szazalek).slice(0, 2)
      return d
    },
    init() {
      this.districts = this.data.districts
      this.myDistrict = this.sortData(this.districts[0])
    },
    show() {
    },
    onSearch(search) {
      this.myDistrict = this.sortData(search)
    },
    filterSearch(options, label, search) {
      return (options.nev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) ||
          (options.szekhely.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
</style>
