import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import VueI18n from 'vue-i18n'
import i18nMessages from '@/locales/hu.json'
import geoCountyData from '@/assets/map/county.optimized.json'
import geoDistrictData from '@/assets/map/district.optimized.json'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Map from '@/components/Map'
import MapResults from '@/components/MapResults'
import MapDashboard from '@/components/MapDashboard'
import MapParticipation from '@/components/MapParticipation'
import Seats from '@/components/Seats'
import ParticipationBar from '@/components/ParticipationBar.vue'
import ResultBar from '@/components/ResultBar.vue'
import Dashboard from '@/components/Dashboard.vue'
import MyDistrict from '@/components/MyDistrict.vue'
import ArticleAccordionOevk from '@/components/ArticleAccordionOevk.vue'
import ArticleAccordionOvList from '@/components/ArticleAccordionOvList.vue'
import ElectionVote from '@/components/ElectionVote.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)
const routes = [
  {
    path: "/election-2022-map",
    component: Map,
    props: (route) => ({
      county: route.query.county,
      district: route.query.district
    })
  },
  {
    path: "/election-2022-map-results",
    component: MapResults,
    props: (route) => ({
      county: route.query.county,
      district: route.query.district
    })
  },
  {
    path: "/election-2022-map-participation",
    component: MapParticipation,
    props: (route) => ({
      county: route.query.county,
      district: route.query.district
    })
  },
  {
    path: "/election-2022-map-dashboard",
    component: MapDashboard
  },
  {
    path: "/election-2022-seats",
    component: Seats,
    props: (route) => ({
      finals: route.query.finals,
      tips: route.query.tips
    })
  },
  {
    path: "/election-2022-participation-bar",
    component: ParticipationBar,
    props: (route) => ({
      history: route.query.history,
      list: route.query.list,
    })
  },
  {
    path: "/election-2022-result-bar",
    component: ResultBar
  },
  {
    path: "/election-2022-dashboard",
    component: Dashboard
  },
  {
    path: "/election-2022-my-district",
    component: MyDistrict
  },
  {
    path: "/election-2022-article-accordion-oevk",
    component: ArticleAccordionOevk
  },
  {
    path: "/election-2022-article-accordion-ov",
    component: ArticleAccordionOvList
  },
  {
    path: "/election-2022-election-vote",
    component: ElectionVote
  },
  // {
  //   path: "/embed-keszites",
  //   component: EmbedGenerator,
  // },
]
const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.use(VueI18n)
const i18n = new VueI18n({
  legacy: false,
  locale: 'hu',
  globalInjection: true,
  messages: i18nMessages
})

const LeafletPlugin = {
  install(Vue) {
    Vue.prototype.$L = L
    Vue.prototype._mapData = {
      geoCountyData, geoDistrictData,
    }
  }
}
Vue.use(LeafletPlugin)
Vue.component('v-select', vSelect)

Vue.mixin({
  data: function() {
    return {
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  i18n,
}).$mount('#app')
