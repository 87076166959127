<template src="./MapDashboard.html"/>
<script>
import Config from '@/config/MapDashboard.json'
import ElectionApi from "@/api/ElectionApi"
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      map: null,
      mapBp: null,
      state: 'district',
      states: {},
      selectedLayerChanged: null,
      data: {},
      layers: {},
      layersBp: {},
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.data.districts = data[0]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
  },
  methods: {
    async loadData() {
      return Promise.all([
        ElectionApi.getDistricts(),
      ])
    },
    init() {
      this.states.district = {
        data: this._mapData.geoDistrictData,
      }
      this.map = this.$L.map('mapdashboard' + this._uid, Config.mapOptionsStatic)
      this.mapBp = this.$L.map('mapdashboard-bp' + this._uid, Config.mapOptionsStatic)
    },
    show() {
      this.layers = this.getLayers(this.states.district.data.features)
      this.layersBp = this.getLayers(this.states.district.data.features.filter(feature => feature.properties.county_id === '01'))
      this.layers.addTo(this.map)
      this.map.fitBounds(this.layers.getBounds(), Config.mapStyles.animate)
      this.layersBp.addTo(this.mapBp)
      this.mapBp.fitBounds(this.layersBp.getBounds(), Config.mapStyles.animate)
      document.getElementById('dashboard-22-size')?.addEventListener('change', this.refresh)
    },
    getLayers(data) {
      return this.$L.geoJSON(data, {
        style: (feature) => this.getLayerStyle(feature),
      })
    },
    getLayerStyle(feature) {
      const oevkId = feature.properties.oevk_id.toString()
      const district = this.data.districts.filter(d => d.oevkId === oevkId)[0]
      const candidate = district?.vezet
      const percent = candidate ? Math.ceil((district?.feldolgozottsag || 0) / 10) / 10 : 1
      // const candidates = this.data.districts.jeloltek
      // const candidate = candidates.reduce(
      //     (max, c) => (max.szavazat < (c.szavazat || 0)) ? c : max, {
      //       szavazat: 0,
      //       jeloloCsoport: {
      //         jlcs: 'transparent'
      //       }
      //     })
      // const participations = this.data.participation.filter(p => p.oevkId === oevkId)
      // const participation = participations.reduce(
      //     (max, p) => (max.megjelent < p.megjelent) ? p : max, {
      //       szazalek: 100,
      //       megjelent: 0,
      //     })
      // const percent = (participation.szazalek * 100) / 10000
      return {
        ...Config.mapStyles.underlay,
        fillColor: Config.jlcs['group' + candidate?.jeloloCsoport?.jlcs] || 'rgba(255, 255, 255, 1)',
        fillOpacity: percent,
      }
    },
    refresh() {
      setTimeout(() => {
        this.map.invalidateSize()
        this.map.fitBounds(this.layers.getBounds(), Config.mapStyles.animate)
        this.mapBp.invalidateSize()
        this.mapBp.fitBounds(this.layersBp.getBounds(), Config.mapStyles.animate)
      }, 200)
    }
  },
}
</script>
<!--
<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/map.scss';
</style>
-->
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
.dashboard-22__content {
  .leaflet-interactive {
    cursor: default;
  }
  .election-2022-map.map-container {
    border: none;
  }
}
</style>
