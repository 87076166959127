<template>
  <div id="app" class="light-mode">
    <router-view />
  </div>
</template>

<script>
import "@mdi/font/css/materialdesignicons.min.css"

export default {
  name: 'App',
}
</script>
<style lang="scss">
//2022 breakpoints
$xs-max: 374px;
$s-min: 375px;
$s-max: 499px;
$m-min: 500px;
$m-max: 767px;
$l-min: 768px;
$l-max: 1199px;
$xl-min: 1200px;
@mixin xs() { @media screen and (max-width: $xs-max) { @content; } }
@mixin s() { @media screen and (min-width: $s-min) { @content; } }
@mixin m() { @media screen and (min-width: $m-min) { @content; } }
@mixin l() { @media screen and (min-width: $l-min) { @content; } }
@mixin xl() { @media screen and (min-width: $xl-min) { @content; } }
@mixin xs() { @media screen and (max-width: $xs-max) { @content; } }
@mixin s() { @media screen and (min-width: $s-min) { @content; } }
@mixin m() { @media screen and (min-width: $m-min) { @content; } }
@mixin l() { @media screen and (min-width: $l-min) { @content; } }
@mixin xl() { @media screen and (min-width: $xl-min) { @content; } }

body {
  margin: 0;
  padding: 0;
  font-family: Inter,Helvetica,Arial,sans-serif;
}

ol {
  padding: 0;
}

//OV 2022
.list {
  //ov part patko
  .seats--org-list {
    background: var(--color-gray-light);
    color: var(--color-text);
    border: 2px solid var(--color-gray-mid-light);
    transition: var(--theme), var(--theme-text), var(--theme-border);
    border-radius: 5px;
    width: 100%;
    padding: 20px 15px;
    margin: 0 0 30px 0;
    svg {
      width: 80px !important;
      max-width: 80px !important;
      @include s {
        width: 100px !important;
        max-width: 100px !important;
      }
      @include m {
        width: 150px !important;
        max-width: 150px !important;
      }
      @include l {
        width: 200px !important;
        max-width: 200px !important;
      }
    }
    @include m {
      padding: 30px 20px;
    }
    .patko--info--1798:before {
      background: #f60;
    }
  }
  //ov reszvetel
  .participation--list {
    background: var(--color-gray-light);
    color: var(--color-text);
    border: 2px solid var(--color-gray-mid-light);
    transition: background-color 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;
    border-radius: 5px;
    padding: 20px 15px;
    width: 100%;
    margin: 0 0 30px 0;
    .dashboard-22__progress__line:after {
      color:var(--color-text);
      transition: var(--theme-text);
    }
  }
  //ov barcharts
  .dashboard-22__progress {
    span {
      font-size: .88rem;
    }

    .dashboard-22__progress__line {
      margin: 20px 0;
      max-width: 99%;
      &:before {
        display: none;
      }

      &:after {
        font-size: .8rem;
        left: -10px;
        top: -25px;
        transform: none;
      }

      &:first-of-type {
        margin-top: 40px;

        &:after {
          content: 'Aktuális részvétel';
        }
      }

      &:nth-child(3):after {
        content: '2018-as részvétel ';
      }

      &:nth-child(4):after {
        content: '2002-es rekord (2. forduló)';
      }
    }
  }
  /*
//ov maps
 .participation-map--list {}
 .results-map--list {}
 .candidates-map--list {}
}*/
  //ov tipp
  .patko--info--1:before {
    background: var(--ov-1) !important;
  }
  .patko--info--2:before {
    background: var(--ov-2) !important;
  }
  .patko--info--3:before {
    background: var(--ov-3) !important;
  }
  .ov-border {
    fill: transparent;
  }
}

//accordions
.accordion {
  margin: 0 0 10px 0;
  &__head {
    width: 100%;
    padding:4px 10px 4px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: var(--bold);
    border-radius: 3px;
    background: var(--color-gray-mid-light);
    color: var(--color-text);
    border: 1px solid var(--color-gray-medium);
    transition: var(--theme), var(--theme-text), var(--theme-border);
    @include m {
      padding:4px 25px 4px 30px;
    }
    &:hover {
      opacity: .8;
    }
  }
  &__icon {
    display: grid;
    place-content: center;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  i {
    font-size: 2rem;
    line-height: 1;
  }
  &__title {
    font-size: .8rem;
    @include m {
      font-size: .88rem;
    }
  }
  &__content {
    padding: 15px;
    border: 1px solid  var(--color-gray-medium);
    border-top: none;
    background: var(--color-list);
    transition: var(--theme), var(--theme-border);
    overflow: hidden;
    @include m {
      padding: 15px 30px;
    }
  }
  &:last-child {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 40px;
    }
  }
}

</style>
