<template src="./Dashboard.html"/>
<script>
import ElectionApi from '@/api/ElectionApi.js'
import ParticipationBar from "@/components/ParticipationBar"
import ResultBar from "@/components/ResultBar"
import MapDashboard from "@/components/MapDashboard"
import Seats from "@/components/Seats"
import MyDistrict from "@/components/MyDistrict"

export default {
  components: {
    ParticipationBar,
    ResultBar,
    MapDashboard,
    Seats,
    MyDistrict,
  },
  props: {
    options: {},
  },
  data() {
    return {
      status: '', // ures, torzsadatok, napkozbeni, eredmenyek
      feldolgozottsag: 0,
      frissules: '',
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.status = data[0].status || ''
      this.feldolgozottsag = parseFloat(data[0].feldolgozottsag) || 0
      this.frissules = data[0].verzio || ''
    }).catch(() => {
    }).finally(() => {
      this.$nextTick(() => {
      })
    })
  },
  computed: {
    isParticipation() {
      return this.getOption('election-2022-participation') && ['napkozbeni', 'eredmenyek'].includes(this.status)
    },
    isResultBar() {
      return this.getOption('election-2022-result-show')
          && ['eredmenyek'].includes(this.status)
          && this.feldolgozottsag > 10.0
    },
  },
  filters: {
  },
  methods: {
    async loadData() {
      return Promise.all([
        ElectionApi.getStatus(),
      ])
    },
    getOption(key) {
      const item = this.options?.uniques.filter(item => item.slug === key)[0] || {}
      return item?.content || null
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
</style>
