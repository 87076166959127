<template src="./ResultBar.html"/>
<script>
import ElectionApi from '@/api/ElectionApi.js'
const leftJlcs = 1798
const rightJlcs = 1803
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      data: {},
      result: {
        grid: {
          left: 0,
          right: 0,
        }
      },
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.data.result = data[0]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
  },
  filters: {
    format: function (value) {
      return new Intl.NumberFormat('hu-HU').format(value)
    }
  },
  methods: {
    async loadData() {
      return Promise.all([
          ElectionApi.getResults(),
      ])
    },
    init() {
      this.result = {
        left: this.data.result.filter(e => e.jeloloCsoport.jlcs === leftJlcs)[0] || this.data.result[0] || {},
        right: this.data.result.filter(e => e.jeloloCsoport.jlcs === rightJlcs)[0] || this.data.result[1] || {},
      }
      const percent = (parseFloat(this.result.left.eredmeny.szazalek) + parseFloat(this.result.right.eredmeny.szazalek)) / 100
      this.result.grid = {
        left: Math.round((parseFloat(this.result.left.eredmeny.szazalek) / percent) * 10) / 10,
        right: Math.round((parseFloat(this.result.right.eredmeny.szazalek) / percent) * 10) / 10,
      }
    },
    show() {
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
</style>
