<template src="./MapResults.html"/>
<script>
import { version } from '../../package'
import Config from '@/config/MapResults.json'
import ElectionApi from '@/api/ElectionApi.js'
export default {
  components: {
  },
  props: {
    propCounty: {
      type: String,
      required: false,
      default: null,
    },
    propDistrict: {
      type: String,
      required: false,
      default: null,
    },
    version: {
      type: String,
      default: version,
    }
  },
  data() {
    return {
      map: null,
      state: Config.defaultState,
      states: JSON.parse(JSON.stringify(Config.mapStates)),
      selectedLayerChanged: null,
      data: {},
      search: {
        show: false,
      },
      underlay: {},
    }
  },
  watch: {
    state(newValue) {
      this.$emit('stateChanged', newValue)
    },
    'states.county.selected': {
      immediate: true,
      handler(newValue) {
        this.$emit('countyChanged', newValue)
      }
    },
    'states.district.selected'(newValue) {
      this.$emit('districtChanged', newValue)
    },
  },
  mounted() {
    this.loadData().then(data => {
      this.data.districts = data[0]
      this.data.candidates = data[0].reduce((result, item) => (
          ([...result, ...(item.jeloltek.map(j => j))])
      ), [])
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
    getSelectedCountyName() {
      this.selectedLayerChanged
      const selectedLayer = this.getSelectedLayer()
      let county = (selectedLayer && selectedLayer.feature.properties.name) || ''
      return county === 'Budapest' ? 'budapest' : county
    },
    getSelectedDistrictName() {
      this.selectedLayerChanged
      const selectedLayer = this.getSelectedLayer()
      let district = (selectedLayer && selectedLayer.feature.properties.oevk_center) || ''
      return district
    },
  },
  methods: {
    async loadData() {
      return Promise.all([
        ElectionApi.getDistricts(),
        // ElectionApi.getCandidates(),
        // ElectionApi.getCandidatesHistory(),
        // ElectionApi.getResults(),
      ])
    },
    init() {
      this.state = this.propCounty || this.$route.query.county ? "district" : Config.defaultState
      this.states.county.selected = this.propCounty || this.$route.query.county || '01'
      this.states.county.data = this._mapData.geoCountyData
      this.states.district.selected = this.propDistrict || this.$route.query.district || null
      this.states.district.data = this._mapData.geoDistrictData
      this.map = this.$L.map('map' + this._uid, {
        ...Config.mapOptions,
        ...{
          dragging: !this.$L.Browser.mobile,
          tap: !this.$L.Browser.mobile }
      })
      this.underlay = this.getUnderlay(this.states.district.data.features)
    },
    show() {
      // get current state
      const currentState = this.getCurrentState()
      // get map layers from geodata
      currentState.layers = this.state === 'county' ? this.getCountyLayers(this.getLayersData()) : this.getDistrictLayers(this.getLayersData())
      // get selected layer
      const selectedLayer = this.getSelectedLayer()
      // set selected layer style and value
      selectedLayer && this.state === 'county' ? selectedLayer.setStyle(Config.mapStyles.selected) : selectedLayer.setStyle(Config.mapStyles.selectedDistrict)
      selectedLayer && this.setSelected(selectedLayer.feature.properties[currentState.key])
      // set bounds
      this.map.flyToBounds(currentState.layers.getBounds(), Config.mapStyles.animate)
      // underlay layer
      this.showUnderlay(this.state === 'county')
      // add layers to map
      currentState.layers.addTo(this.map)
      let message = {
        type: 'iframe-size',
        sourceAddress: window.location.href,
        height: document.body.scrollHeight + 20,
        width: document.body.scrollWidth,
      };
      // window.top refers to parent window
      this.$nextTick(() => window.parent.postMessage(message, '*'))
    },
    getLayersData() {
      const currentState = this.getCurrentState()
      return currentState.data.features.filter(feature => !currentState.parent || (feature.properties[currentState.parent]?.toString() === this.states[currentState.parentState]?.selected?.toString()))
    },
    getUnderlay(data) {
      return this.$L.geoJSON(data, {
        style: (feature) => this.getLayerStyle(feature),
      })
    },
    getCountyLayers(data) {
      return this.$L.geoJSON(data, {
        style: Config.mapStyles.county,
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              const layer = e.target
              layer.setStyle({...Config.mapStyles.selected, ...Config.mapStyles.hover})
            },
            mouseout: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              // layer.setStyle((layer.feature.properties[currentState.key]?.toString() === this.getSelected()?.toString()) ? Config.mapStyles.selected : Config.mapStyles.default)
              if (layer.feature.properties[currentState.key]?.toString() === this.getSelected()?.toString()) {
                layer.setStyle(Config.mapStyles.selected)
              } else {
                currentState.layers.resetStyle(layer)
              }
            },
            click: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              const selectedLayer = this.getSelectedLayer()
              // selectedLayer && selectedLayer.setStyle(Config.mapStyles.default)
              currentState.layers.resetStyle(selectedLayer)
              layer.setStyle(Config.mapStyles.selected)
              this.setSelected(layer.feature.properties[currentState.key])
            },
          })
        }
      })
    },
    getDistrictLayers(data) {
      return this.$L.geoJSON(data, {
        style: (feature) => this.getDistrictLayerStyle(feature),
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              const layer = e.target
              layer.setStyle({...Config.mapStyles.selectedDistrict, ...Config.mapStyles.hoverDistrict})
            },
            mouseout: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              // layer.setStyle((layer.feature.properties[currentState.key]?.toString() === this.getSelected()?.toString()) ? Config.mapStyles.selected : Config.mapStyles.default)
              if (layer.feature.properties[currentState.key]?.toString() === this.getSelected()?.toString()) {
                layer.setStyle(Config.mapStyles.selectedDistrict)
              } else {
                currentState.layers.resetStyle(layer)
              }
            },
            click: (e) => {
              const layer = e.target
              const currentState = this.getCurrentState()
              const selectedLayer = this.getSelectedLayer()
              // selectedLayer && selectedLayer.setStyle(Config.mapStyles.default)
              currentState.layers.resetStyle(selectedLayer)
              layer.setStyle(Config.mapStyles.selectedDistrict)
              this.setSelected(layer.feature.properties[currentState.key])
            },
          })
        }
      })
    },
    getLayerStyle(feature) {
      const oevkId = feature.properties.oevk_id
      const district = this.data.districts.filter(d => d.oevkId.toString() === oevkId.toString())[0]
      const candidate = district?.vezet
      const percent = candidate ? Math.ceil((district?.feldolgozottsag || 0) / 10) / 10 : 1
      return {
        ...Config.mapStyles.underlay,
        fillColor: Config.jlcs['group' + candidate?.jeloloCsoport?.jlcs] || '#f1f1f1',
        fillOpacity: percent,
      }
    },
    getDistrictLayerStyle(feature) {
      const oevkId = feature.properties.oevk_id
      const district = this.data.districts.filter(d => d.oevkId.toString() === oevkId.toString())[0]
      const candidate = district?.vezet
      const percent = candidate ? Math.ceil((district?.feldolgozottsag || 0) / 10) / 10 : 1
      return {
        ...Config.mapStyles.underlay,
        fillColor: Config.jlcs['group' + candidate?.jeloloCsoport?.jlcs] || '#f1f1f1',
        fillOpacity: percent,
      }
    },
    getCurrentState() {
      return this.states[this.state]
    },
    getSelected() {
      return this.getCurrentState().selected
    },
    getSelectedLayer() {
      const currentState = this.getCurrentState()
      if (!currentState || !currentState.layers)
        return null
      const selectedLayer = currentState.layers.getLayers().filter(
          layer => layer.feature.properties[currentState.key]?.toString() === (currentState.selected || '')?.toString()
      )[0]
      return this.selectedLayerChanged = (selectedLayer || currentState.layers.getLayers()[0])
    },
    setSelected(value) {
      this.getCurrentState().selected = value
    },
    onBack() {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = this.getCurrentState().parentState || Config.defaultState
      this.show()
    },
    onNext() {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = this.getCurrentState().childState || Config.defaultState
      this.show()
    },
    onShowSearch() {
      this.search.show = true
      this.$nextTick(() => {
        this.$refs.searchSelect.$el.querySelector('input').focus()
      })
    },
    onSearch(search) {
      this.map.removeLayer(this.getCurrentState().layers)
      this.state = "county"
      this.setSelected(search.maz)
      this.state = "district"
      this.setSelected(search.oevkId)
      this.search.show = false
      this.show()
    },
    filterSearch(options, label, search) {
      return (options.nev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) ||
          (options.oevkNev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) ||
          (options.jeloloCsoport.nev.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
    },
    getCandidates() {
      const candidates = this.data.districts.filter(d => d.oevkId === this.getCurrentState().selected?.toString())[0]?.jeloltek || []
      return candidates.sort((e1, e2) => parseInt(e2.szavazat) - parseInt(e1.szavazat))
    },
    getOrderedCandidates() {
      return this.data.candidates.sort((e1, e2) => e1.oevkId - e2.oevkId)
    },
    getHistory() {
      return []
      // const items = this.data.history.filter((item) => item.oevkId === this.getCurrentState().selected?.toString())
      // const sum = items.reduce((sum, item) => sum + item.szavazat, 0)
      // items.map((item) => item.percent = Math.round(((item.szavazat / sum) * 100) * 10) / 10)
      // items.sort((e1, e2) => e2.percent - e1.percent)
      // return items
    },
    showUnderlay(show) {
      if (show) {
        this.underlay.addTo(this.map)
      } else {
        this.map.removeLayer(this.underlay)
      }
    },
  },
}
</script>
<style lang="scss">
.group641 .candidate--bull,
.group1061 .candidate--bull {
  display: block !important;
  background: #f60;
}
.group1803 .candidate--bull,
.group1062 .candidate--bull {
  display: block !important;
  background: #f60;
}
.group1750 .candidate--bull {
  display: block !important;
  background: #e44;
}
.group1752 .candidate--bull {
  display: block !important;
  background: #680;
}
</style>
