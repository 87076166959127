<template src="./Seats.html"/>
<script>
import ElectionApi from '@/api/ElectionApi.js'
import sizeReporter from "../mixins/sizeReporter";
const NUM_SEATS = 199

export default {
  components: {
  },
  mixins: [ sizeReporter ],
  props: {
    finals: {
      type: String,
      default: null,
    },
    tips: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      data: {},
      seats: {},
    }
  },
  mounted() {
    this.loadData().then(data => {
      this.data.lists = data[0]
      this.data.districts = data[1]
    }).catch(() => {
      this.data.nodata = true
    }).finally(() => {
      this.$nextTick(() => {
        this.init()
        this.show()
      })
    })
  },
  computed: {
    getStyle() {
      let index = 1, css = ''
      if (this.finals) {
        if (this.seats[0]) {
          index = 1
          for (let cnt = 0; cnt < this.seats[0].value; cnt++) {
            css += 'path.ov-' + index++ + ','
          }
          css += 'path.ov-x {fill: var(--ov-' + this.seats[0].class + ') !important;} '
        }
        if (this.seats[1]) {
          index = NUM_SEATS
          for (let cnt = 0; cnt < this.seats[1].value; cnt++) {
            css += 'path.ov-' + index-- + ','
          }
          css += 'path.ov-x {fill: var(--ov-' + this.seats[1].class + ') !important;} '
        }
      } else {
        for (let i = 0; i < this.seats.length; i++) {
          for (let cnt = 0; cnt < this.seats[i].value; cnt++) {
            css += 'path.ov-' + index++ + ','
          }
          css += 'path.ov-x {fill: var(--ov-' + this.seats[i].class + ') !important;} '
        }
      }
      return css
    },
    getNames() {
      let names = []
      const count = this.finals ? 2 : this.seats.length
      for (let i = 0; i < count; i++) {
        if (this.seats[i]) {
          names.push({
            name: this.seats[i].name,
            value: this.seats[i].value,
            class: 'patko--info--' + this.seats[i].class,
          })
        }
      }
      return names
    },
  },
  methods: {
    async loadData() {
      return Promise.all([
        this.tips ? ElectionApi.getResultsTips(this.tips) : ElectionApi.getResults(),
        ElectionApi.getDistricts(),
      ])
    },
    init() {
      if (this.tips) {
        this.seats = this.data.lists
      } else {
        this.data.districts.map((e) => {
          this.seats[e.vezet.jeloloCsoport.jlcs] = {
            name: e.vezet.jeloloCsoport.nev,
            value: this.seats[e.vezet.jeloloCsoport.jlcs]?.value+1 || 1,
            class: e.vezet.jeloloCsoport.jlcs
          }
        })
        this.data.lists.map((e) => {
          if (e.eredmeny.mandatumokSzama > 0) {
            this.seats[e.jeloloCsoport.jlcs] = {
              name: e.jeloloCsoport.nev,
              value: this.seats[e.jeloloCsoport.jlcs]?.value + e.eredmeny.mandatumokSzama || e.eredmeny.mandatumokSzama,
              class: e.jeloloCsoport.jlcs
            }
          }
        })
        this.seats = Object.values(this.seats).sort((e1, e2) => e2.value - e1.value)
      }
    },
    show() {
      this.setResizeEventListener()
      this.sendSizeMessage()
    },
    getJlcsNev(jeloloCsoport) {
      return jeloloCsoport.jlcs // nev.toLowerCase().replace(/[^a-z0-9]/gi, '')
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/dashboard.scss';
/*
.dashboard-22 {
  &__seats {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 30px;
    align-items: center;
    @include m {
      grid-template-columns: 110px 1fr;
    }
    &__info {
      display: flex;
      flex-direction: column;
      span {
        position: relative;
        font-size: .77rem;
        font-weight: $font-bold;
        margin: 0 0 3px 15px;
        &:before {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          top:3px;
          left: -22px;
          border: 1px solid white;
          border-radius: 3px;
        }
      }
    }
    #fidesz:before {
      background: var(--ov-fidesz);
    }
    #ellenzek:before {
      background: var(--ov-ellenzek);
    }
  }
}
 */
$primary: #096;
$accent: $primary;
$black: #222;
$font-bold: 700;
$theme: background-color .3s ease-out;
$theme-border: border .3s ease-out;
$theme-txt: color .3s ease-out;
$theme-fill: fill .3s ease-out;
$theme-gradient: gradient .3s ease-out;
.ov-patko {
  &-info {
    max-width: 350px;
    margin: 0 auto;
  }
  path {
    fill: transparent;
  }
  #ov-60,
  #ov-61,
  #ov-62,
  #ov-63,
  #ov-64,
  #ov-65,
  #ov-66,
  #ov-67 {
    fill: var(--ov-fidesz);
  }
  #ov-68,
  #ov-69,
  #ov-70,
  #ov-71,
  #ov-72,
  #ov-73,
  #ov-74,
  #ov-75,
  #ov-76,
  #ov-77{
    fill: var(--ov-ellenzek);
  }
  #ov-bg {
    fill: #ccc;
  }
  #ov-border {
    fill: white;
  }
}

//tippjatek patko
.ov-patko {
  --ov-egybprtok: #85e;
  --ov-1062:#85e;
  --ov-egysgbenmagyarorszgrt: #5ba;
  --ov-1072: #f2f2f2;
  margin: 30px auto;
  max-width: 200px;
  &-info {
    margin: 5px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include m {
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    span {
      font-weight: var(--bold);
      font-size: .9rem;
      position: relative;
      margin: 0 0 0 30px;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: -25px;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 2px solid var(--color-light);
        background: #999;
      }
    }
    .patko--info--egysgbenmagyarorszgrt:before {
      background: #5ba;
    }
    .patko--info--egybprtok:before {
      background: #85e;
    }
    }
  .ov-bg
  {
    fill: #bbb;
  }
}
.ov-border {
  fill: var(--color-light);
}
//melleklet patko
.seats--org-list {
  &__content {
    display: grid;
    grid-template-columns: 80px 1fr;
    max-width: 450px;
    grid-gap: 50px;
    margin: 0 auto;
    align-items: center;
    @include s {
      grid-template-columns: 100px 1fr;
      grid-gap: 60px;
    }
    @include m {
      grid-template-columns: 150px 1fr;
    }
    @include l {
      grid-template-columns: 200px 1fr;
    }
    .ov-patko {
      margin: 0;
      &-info {
        margin: 0;
        span {
          margin: 0 0 10px 0;
        }
      }
    }
    .patko--info--1061:before,
    .patko--info--641:before {
      background: var(--ov-fideszkdnp);
    }
    .patko--info--1063:before,
    .patko--info--1803:before {
      background: var(--ov-ellenzek);
    }
    .patko--info--1750:before {
      background: var(--ov-mkkp);
    }
    .patko--info--1752:before {
      background: var(--ov-mihazank);
    }
    .patko--info--1752:before {
      background: var(--ov-mihazank);
    }
    .patko--info--1786:before {
      background: var(--color-gray-medium);
    }
    .patko--info--1062:before {
      background: var(--ov-egybprtok);
    }
  }
}
</style>
