<template src="./ArticleAccordion.html" />
<script>
export default {
  name: 'ArticleAccordion',
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
      let message = {
        type: 'iframe-size',
        sourceAddress: window.location.href,
        height: document.body.scrollHeight + 80,
        width: document.body.scrollWidth,
      };
      // window.top refers to parent window
      this.$nextTick(() => window.parent.postMessage(message, '*'))
    },
  },
}
</script>

<style scoped lang="scss">
.rotate-180 {
  background: yellow;
  transform: rotate(180deg);
}
.rotate-0 {
  background: yellowgreen;
}
</style>
