export default {
  methods: {
    setResizeEventListener() {
      // create an Observer instance
      const resizeObserver = new ResizeObserver(() => this.sendSizeMessage())
      // start observing a DOM node
      resizeObserver.observe(document.body)
    },
    sendSizeMessage() {
      let message = {
        type: 'iframe-size',
        sourceAddress: window.location.href,
        height: document.body.scrollHeight + 81,
        width: document.body.scrollWidth,
      };
      // window.top refers to parent window
      this.$nextTick(() => window.parent.postMessage(message, '*'))
    }
  }
}
