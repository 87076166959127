<template src="./ArticleAccordionOvList.html" />

<script>
import ArticleAccordion from './ArticleAccordion'
import ElectionApi from "../api/ElectionApi";
import sizeReporter from "../mixins/sizeReporter";

export default {
  name: 'ArticleAccordionOvList',
  key: '_article_accordion_ov_list',
  components: { ArticleAccordion },
  mixins: [ sizeReporter ],
  props: {
    propTipus: {
      type: String,
      default: 'partok', // partok || nemzetisegek
    },
  },
  data() {
    return {
      tipus: null,
      ovList: null,
    }
  },
  watch: {
    tipus() {
      this.fetchData()
    },
  },
  mounted() {
    this.tipus = this.$route.query.tipus ?? this.propTipus
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadData().then(data => {
        this.ovList = data[0]
            .filter(_ => _.nemzetiseg === '' ? this.tipus === 'partok' : this.tipus === 'nemzetisegek')
            .sort((a, b) => (a.eredmeny.mandatumokSzama > b.eredmeny.mandatumokSzama)
                ? 1
                : ((a.eredmeny.mandatumokSzama < b.eredmeny.mandatumokSzama)
                        ? -1
                        : (a.sorszam > b.sorszam ? 1 : -1)
                ),
            )
      }).finally(() => {
        this.setResizeEventListener()
        this.sendSizeMessage()
      })
    },
    async loadData() {
      return Promise.all([
        ElectionApi.getOvList(2022),
      ])
    },
  },
}
</script>
<style lang="scss">
$primary: #096;
$accent: $primary;
$black: #222;
$font-bold: 700;
$theme: background-color .3s ease-out;
$theme-border: border .3s ease-out;
$theme-txt: color .3s ease-out;
$theme-fill: fill .3s ease-out;
$theme-gradient: gradient .3s ease-out;
.ov22 {
  .accordion__content {
    max-height: 250px;
    overflow: scroll;
    &--text{
      font-size: .7rem !important;
      font-style: italic;
      color: $black;
      margin: 0 !important;
      display: inline;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    counter-reset: section;
    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      & > * {
        margin: 3px 0 0 0;
      }
      &.numbered:before {
        counter-increment: section;
        content: counter(section) ".";
        margin-right: 10px;
      }
    }
    &__name {
      font-weight: $font-bold;
      font-size: .88rem;
    }
    &__org-name {
      font-size: .8rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &__dot {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      display: none;
      margin-right: 5px;
    }
    &__org {
      position: relative;
      font-size: .8rem;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 8px;
        top: 50%;
        transform: translateY(-50%);
        left: -15px;
      }
    }
    &__title {
      font-size: 1rem;
      font-weight: $font-bold;
      margin: 30px 0 10px  0;
    }
    &__hint {
      margin-top: 10px;
      display: block;
      z-index: 5;
      position: relative;
      svg {
        fill: var(--color-accent);
        transition: $theme-fill;
        width: 14px;
        transform: translateY(-1px);
      }
      p {
        display: inline;
        color: var(--color-text);
        transition: $theme-txt;
        font-size: .8rem;
      }
    }
    p {
      width: max-content;
      white-space: nowrap;
      margin: 0 5px 0 0;
    }
  }
  &__org-list {
    &__summary {
      display: flex;
      flex-direction: column;
      margin: 0 0 10px 0;
      span {
        font-size: .9rem;
        b {
          font-size: .88rem;
        }
      }
    }
    &__members {
      font-size: .88rem;
      font-weight: $font-bold;
      line-height: 2;
      span {
        //text-transform: lowercase;
        &:first-letter {
          //color: lime;
          //text-transform: capitalize;
        }
      }
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &__dot {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 5px;
      display: block;
      background: var(--color-gray-medium);
      &.org-1798 {
        background: var(--ov-fidesz);
      }
      &.org-1750 {
        background: var(--ov-mkkp);
      }
      &.org-1803 {
        background: var(--ov-ellenzek);
      }
      &.org-1752 {
        background: var(--ov-mihazank);
      }
    }
  }
  /* fidesz */
  .org-1798 .ov22__list__dot,
  .org-641 .ov22__list__dot {
    display: block;
    background: var(--ov-fidesz);
  }
  /* Összellenzék */
  .org-1803 .ov22__list__dot {
    display: block;
    background: var(--ov-ellenzek);
  }
  /* dk */
  .org-599 .ov22__list__dot {
    display: block;
    background: var(--ov-dk);
  }
  /* lmp */
  .org-580 .ov22__list__dot {
    display: block;
    background: var(--ov-lmp);
  }
  /* Jobbik */
  .org-597 .ov22__list__dot {
    display: block;
    background: var(--ov-jobbik);
  }
  /* Momentum */
  .org-554 .ov22__list__dot {
    display: block;
    background: var(--ov-momentum);
  }
  /* MKKP */
  .org-544 .ov22__list__dot,
  .org-1750 .ov22__list__dot {
    display: block;
    background: var(--ov-mkkp);
  }
  /* Mihazánk */
  .org-1752 .ov22__list__dot {
    display: block;
    background: var(--ov-mihazank);
  }
  /* MSZP */
  .org-644 .ov22__list__dot {
    display: block;
    background: var(--ov-mszp);
  }
  /* Együtt */
  .org-536 .ov22__list__dot {
    display: block;
    background: var(--ov-egyutt);
  }
}
</style>
